import Vue from 'vue'
import Vuex from 'vuex'
import { io } from "socket.io-client";
Vue.use(Vuex)
import router from '../router';
export default new Vuex.Store({
  state: {
    appDimensions: {},
    socket: null,
    licenseKey: null,
    indexedDB: window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB,
    callStatus: '',
    meetingCode: null,
    loginStatus: '',
    socketLicense: null
  },
  mutations: {
    setAppDimensions(state) {
      let newWidht = window.innerWidth;
      let newHeight = window.innerHeight;
      state.appDimensions = {
        width: newWidht,
        height: newHeight
      }
    },
    setLicenseKey(state, key) {
      state.licenseKey = key
    },
    setMeetingCode(state, code) {
      state.meetingCode = code
    },
    setCallStatus(state, status) {
      state.callStatus = status
    },
    setLoginStatus(state, status) {
      state.loginStatus = status
    },
    setOutgoingCall(state, call) {
      state.outGoingCall = call
    }
  },
  actions: {

    // The below will be called from login page
    // If already logged in the below will never be used

    authenticateLicense({ state, getters, dispatch }, licenseNum) {
      console.log('License Number: ', licenseNum)
      //state.socket = io("http://localhost:3000")
      state.socket = io("https://telepresence-app-yre7vtj5na-ue.a.run.app")
      getters.getSocket.on("connect", () => {
        getters.getSocket.emit("initial-kiosk-verify", licenseNum, getters.getSocket.id)
      })
      dispatch('authenticateLicenseResponse')

    },

    licenseAvailability({ state, getters, dispatch }, licenseNumber) {
      // to check whether the license is available or not
      state.socketLicense = io("https://telepresence-app-yre7vtj5na-ue.a.run.app")
      getters.getSocketLicense.on("connect", () => {
        getters.getSocketLicense.emit("kiosk-license-availability", licenseNumber, getters.getSocketLicense.id)
        console.log('Socket ID for License Availability: ', getters.getSocketLicense.id)
      })

      dispatch('kioskLiscenseAvailability')
    },

    kioskLiscenseAvailability({ getters, dispatch }) {
      getters.getSocketLicense.on("license-update", response => {
        console.log('Response: ', response)
        if (response.statusCode === 400) {
          dispatch('deleteLicenseFromIndexedDB')
          getters.getSocketLicense.disconnect()
        }
      })
    },

    authenticateLicenseResponse({ getters, commit, dispatch }) {
      console.log('Inside Authenticate license response')
      getters.getSocket.on("kiosk-license", response => {
        console.log('Response : ', response)
        switch (response.statusCode) {
          case 200:
            dispatch('addLicenseToIndexedDB', response.licenseNumber)
            router.push("/")
            commit('setLicenseKey', response.licenseNumber)
            getters.getSocket.disconnect()
            break;
          case 409:
            commit('setLoginStatus', response.statusMessage)
            getters.getSocket.disconnect()
            break;
          case 403:
            commit('setLoginStatus', response.statusMessage)
            getters.getSocket.disconnect()
            break;
          case 404:
            commit('setLoginStatus', response.statusMessage)
            getters.getSocket.disconnect()
            break;
          case 410:
            commit('setLoginStatus', response.statusMessage)
            getters.getSocket.disconnect()
            break;
          case 411:
            commit('setLoginStatus', response.statusMessage)
            getters.getSocket.disconnect()
            break;
          default:
            break;
        }
      })
    },

    // Call Initiation 
    createSocketConnection({ getters, commit, dispatch, state }) {
      state.socket = io("https://telepresence-app-yre7vtj5na-ue.a.run.app")
      getters.getSocket.on("connect", () => {
        if (getters.getSocket.id !== undefined) {
          commit(
            "setCallStatus",
            "Your are connected to Socket.io with id: " + getters.getSocket.id
          )
          dispatch('handleCall')
          dispatch('initiateCall', getters.getSocket.id)
        }
      })
    },



    initiateCall({ getters }, socketID) {
      console.log('License at Initiate Call : ', getters.getLicenseKey)
      getters.getSocket.emit("initiate-call", {
        kioskID: getters.getLicenseKey,
        connectionID: socketID
      });
    },

    endCall({ getters }) {
      getters.getSocket.emit("end-call", getters.getOutgoingCall)
    },

    handleCall({ getters, commit }) {
      getters.getSocket.on("call-status", (status) => {
        commit('setOutgoingCall', status.meetingCode)
        console.log('Status at Handle call', status);
        switch (status.statusCode) {
          case 403:
            commit("setCallStatus", status.statusMessage);
            getters.getSocket.disconnect()
            break;
          case 404:
            // No Available Users
            commit("setCallStatus", status.statusMessage);
            getters.getSocket.disconnect()
            break;
          case 100:
            console.log('Accepted meeting: ', status)
            commit("setCallStatus", status.statusMessage);
            commit("setMeetingCode", status.meetingCode.call.meetingCode)
            router.push("/telepresence");
            break;
          case 101:
            commit("setCallStatus", status.statusMessage);
            getters.getSocket.disconnect()
            break;
          case 102:
            commit("setCallStatus", status.statusMessage);
            getters.getSocket.disconnect()
            break;
          case 103:
            commit("setCallStatus", status.statusMessage);
            getters.getSocket.disconnect()
            break;
          case 201:
            commit("setCallStatus", status.statusMessage);
            break;
        }
      });
    },

    addLicenseToIndexedDB({ state }, licenseNum) {
      if (!state.indexedDB) {
        console.log("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.");
      } else {
        var request = state.indexedDB.open("verificationKey", 2);
        request.onerror = event => {
          console.log('Something went wrong: ', event)
        };

        request.onupgradeneeded = event => {
          var db = event.target.result
          db.createObjectStore("verificationKey", { keyPath: "licensekey" });
        }

        request.onsuccess = event => {
          var db = event.target.result
          var transaction = db.transaction(["verificationKey"], "readwrite");
          var objectStore = transaction.objectStore("verificationKey");
          var request = objectStore.add({ licensekey: licenseNum });
          request.onsuccess = event => {
            console.log('Added data to Indexed DB: ', event.target.result)
          };
          transaction.oncomplete = event => {
            console.log(event.target.result)
          }

          transaction.onerror = event => {
            console.log('Something is going wrong: ', event.target.result)
          }
        };
      }
    },

    pullLicenseFromIndexedDB({ commit, state }) {
      if (!state.indexedDB) {
        console.log("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.");
      } else {
        // open the database. New Database will be created if not already present
        var request = state.indexedDB.open("verificationKey", 2);

        request.onsuccess = event => {
          var db = event.target.result
          if (db.objectStoreNames.length === 0) {
            router.push('/login')
            indexedDB.deleteDatabase("verificationKey", 2).onsuccess = event => {
              console.log(event)
            }
          } else {
            db.transaction("verificationKey").objectStore("verificationKey").getAll().onsuccess = event => {
              commit('setLicenseKey', event.target.result[0].licensekey)
            }
          }
        }
      }
    },

    deleteLicenseFromIndexedDB({ state }) {
      if (!state.indexedDB) {
        console.log("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.");
      } else {
        indexedDB.deleteDatabase("verificationKey", 2).onsuccess = event => {
          console.log(event)
        }
        location.reload()
      }
    }

    // checkLicense({ commit }){

    // }
  },
  getters: {
    appDimensions: state => state.appDimensions,
    getSocket: state => state.socket,
    getSocketLicense: state => state.socketLicense,
    getLicenseKey: state => state.licenseKey,
    getMeetingCode: state => state.meetingCode,
    getCallStatus: state => state.callStatus,
    getLoginStatus: state => state.loginStatus,
    getOutgoingCall: state => state.outGoingCall
  }
})
