<template>
  <v-row class="fill-height">
    <v-col cols="12" align-self="center">
      <v-card flat align="center">
        <v-img
          src="../assets/building-06.jpg"
          gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
          :height="appDimensions.height"
          :width="appDimensions.width"
        >
          <v-row class="fill-height" no-gutters>
            <v-col cols="12" align-self="center">
              <v-row>
                <v-col cols="4"></v-col>
                <v-col cols="3">
                  <v-text-field
                    outlined
                    label="License Key"
                    solo
                    v-model="license"
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-btn fab @click="authenticateLicense()">
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <span class="text-h5 white--text">{{ getLoginStatus }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-img>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "login",
  computed: {
    ...mapGetters(["appDimensions", "getLoginStatus"]),
  },
  data() {
    return {
      license: "",
    };
  },
  methods: {
    authenticateLicense() {
      this.$store.dispatch("authenticateLicense", this.license);
      this.$store.dispatch("licenseAvailability", this.license);
    },
  },
};
</script>
