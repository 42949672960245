<template>
  <v-row>
    <v-container class="fill-height justify-center" fluid>
      <v-col cols="12" align-self="center" class="pa-0">
        <v-card flat align="center" elevation="10">
          <v-img src="../assets/building-06.jpg" gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
            :height="appDimensions.height" :width="appDimensions.width">
            <v-row class="fill-height">
              <v-col cols="12" align-self="center">
                <v-card :width="appDimensions.width / 3" class="pa-5">
                  <v-card-title class="justify-center">
                    Tap on the button to initiate Video Call
                  </v-card-title>
                  <v-card-actions class="justify-center">
                    <v-card width="130" height="130" @click="initiateCall()" flat>
                      <v-img src="../assets/video-call.png" contain></v-img>
                      <!-- <v-icon left large color="green" class="pa-5">mdi-video-account</v-icon> -->
                    </v-card>
                  </v-card-actions>
                  <v-card flat :width="appDimensions.width / 3.75" outlined>
                    <v-card-text>{{ getCallStatus }}</v-card-text>
                  </v-card>
                </v-card>
              </v-col>
            </v-row>
          </v-img>
        </v-card>
      </v-col>
    </v-container>
  </v-row>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
export default {
  name: "Home",
  components: {
    //
  },
  computed: {
    ...mapGetters(["appDimensions", "getCallStatus"]),
  },
  methods: {
    initiateCall() {
      this.$store.dispatch("createSocketConnection");
    },
  },
};
</script>
<style>
.containerBorder {
  border-width: 3px;
  border-style: solid;
  border-color: black !important;
  border-radius: 8px !important;
}
</style>
