<template>
  <v-app v-resize="onResize">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",

  data: () => ({
    //
  }),
  methods: {
    onResize() {
      this.$store.commit("setAppDimensions");
    },
  },
  computed: {
    ...mapGetters(["getLicenseKey"]),
  },
  mounted() {
    // check if the telepresence is enabled or not
    if (this.getLicenseKey === null) {
      this.$store.dispatch('pullLicenseFromIndexedDB')
    } else {
      if (this.$router.currentRoute.path !== '/') {
        this.$router.push('/')
      }
    }
    //window["console"]["log"] = function () { };
    console.warn = function () { };
  },
};
</script>
<style>
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}
</style>